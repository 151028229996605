.loading {
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingSpinner {
  width: 50px;
  height: 50px;
}

.title {
  font-size: 14px;
  line-height: 16px;
  color: var(--color-gray-400);
  text-transform: capitalize;
}

.more {
  font-size: 14px;
  line-height: 16px;
  background-color: transparent;
  border: none;
  color: var(--color-purple-600);
  outline-color: var(--color-purple-600);
  cursor: pointer;
  margin: 1rem 0;
}

.more:hover {
  text-decoration: underline;
}

.results {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
}

.facetResults {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
