.main {
  display: flex;
  gap: 10px;
  height: fit-content;

  flex-direction: row;
  margin-left: -24px;

  border-bottom: var(--border-gray-1);
  background: var(--color-white);
  padding: 16px;
  grid-area: filters;
}

@container contentRoot (max-width: 640px) {
  .main {
    flex-direction: column;
    margin-inline: -16px;
    padding: 8px;
  }
}
