.pill {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 3px;
  background-color: var(--color-gray-75);
  color: var(--color-gray-600);
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  cursor: default;
}

.pill[data-action="close"] {
  cursor: pointer;
}

.pill[data-action="close"]:hover {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--color-gray-75);
}

.geography {
  width: 14px;
  height: 14px;
  margin-left: 6px;
}

.name {
  color: var(--color-gray-900);
  margin: 3px 5px;
}

.close {
  border: none;
  background-color: transparent;
  padding: 2.5px;
  margin-right: 5px;
  cursor: pointer;
  display: flex;
}

.icon {
  width: 8px;
  height: 8px;
}

.core {
  background-color: #d1fadf;
}

.significant {
  background-color: #faf8d1;
}

.peripheral {
  background-color: #fad1d1;
}
