.main {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 0px 4px 8px;
  border-radius: 4px;
  gap: 4px;
}

.main:hover {
  background-color: var(--color-gray-10);
}

.main[aria-pressed="true"] {
  outline: 2px solid var(--color-purple-600);
  outline-offset: -2px;
}

.name {
  color: var(--color-black);
  font-size: 16px;
  font-weight: 700;
  word-break: initial;
}

.main svg {
  color: var(--color-gray-muted);
}
