.main {
  width: 100%;
  height: 375px;
  position: relative;
  top: 0;
  z-index: 2;
  background-color: var(--color-white);
}

.closeButton {
  padding: 8px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 16px;
  right: 16px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.closeButton:hover {
  background: rgba(0, 0, 0, 0.3);
}

.shaddow {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 71.61%
  );
  width: inherit;
  height: 150px;
  flex-shrink: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 16px 16px 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.name {
  color: var(--color-white);
  font: var(--font-display-b);
  font-size: 18px;
}

.roleTitle {
  color: var(--color-white);
  font: var(--font-text);
  font-size: 14px;
  opacity: 0.7;
}
