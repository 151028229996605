.pill {
  --icon-size: 22px;
  --icon-margin: 5px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  border-radius: 15px;
  min-height: var(--icon-size);
  width: fit-content;
  max-width: 100%;
  padding: 4px 10px 4px 4px;
  font-size: 13px;
  color: var(--color-gray-900);
  user-select: none;
  transition: filter 150ms ease-in-out;
}
.pill:hover {
  filter: brightness(95%) saturate(1.3);
}

.pill[data-interaction='active'] {
  cursor: pointer;
}

.pill[data-interaction='inactive'] {
  cursor: default;
}

.pill[data-type='Context'],
.close[data-type='Context'] {
  background-color: var(--color-cyan-100);
}

.pill[data-type='Actor'],
.close[data-type='Actor'] {
  background-color: var(--color-purple-100);
}

.pill[data-type='Plot'],
.close[data-type='Plot'] {
  background-color: var(--color-pink-100);
}

.pill[data-subscription-status='outside'][data-type='Context'] {
  border: 1px solid var(--color-cyan-100);
  background-color: white;
}

.pill[data-subscription-status='outside'][data-type='Actor'] {
  border: 1px solid var(--color-purple-100);
  background-color: white;
}

.pill[data-subscription-status='outside'][data-type='Plot'] {
  border: 1px solid var(--color-pink-100);
  background-color: white;
}

.pill[data-connection-status='connected'][data-type='Actor'] {
  background-color: var(--color-connected);
}

.image {
  height: var(--icon-size);
  width: var(--icon-size);
  border-radius: 50%;
  object-fit: cover;
}

.name {
  margin-bottom: 0;
  margin-left: var(--icon-margin);
  font: inherit;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 40ch;
}

.pill[data-subscription-status='outside'] .name {
  color: var(--color-gray-100);
}

.pill[data-connection-status='connected'] .name {
  color: white;
}

.close {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: none;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeIcon {
  width: 11px;
  height: 11px;
  opacity: 0.4;
}

.close:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.close:hover .closeIcon {
  opacity: 0.8;
}

.connectedIcon {
  height: var(--icon-size);
  width: var(--icon-size);
  border-radius: 50%;
  opacity: 1;
}

.pill[data-size='small'] {
  --icon-size: 16px;
  --icon-margin: 4px;

  padding: 2px 8px 2px 4px;
  color: var(--color-purple-900);
  font: var(--font-display-md);
  font-size: 12px;
  line-height: 18px;
}
