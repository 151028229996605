.toggleButton {
  cursor: pointer;
  box-sizing: content-box;
  height: 22px;
  padding: 10px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--border-gray-1);
  border-radius: 4px;
  background: var(--color-white);
  box-shadow: var(--shadow-search);
  transition: background-color 150ms ease-in-out;
}
.toggleButton[data-status='expanded'] {
  display: none;
}
.toggleButton:hover {
  background: var(--color-gray-20);
}

.toggleButton > svg {
  fill: var(--color-gray-900);
  width: 18px;
  height: 18px;
}
