.main {
	max-width: var(--width-container);
	background-color: white;
	padding: 16px;
	display: grid;
	gap: 32px;
	grid-template-columns: 55% 45%;
	border-radius: 4px;
	border: 1px solid var(--color-gray-20);
}
