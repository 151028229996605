.main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  isolation: isolate;
}

.filters {
  position: fixed;
  height: 100vh;
  z-index: 3;
  background-color: var(--color-white);
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.12);
  width: 0;
  overflow: hidden;
}

.filters[data-open='true'] {
  width: var(--width-filters);
  overflow: visible;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  width: 100%;
  max-width: var(--width-container);
  background-color: #fafafa;
  padding: 10px 60px;
  container: searchContent / inline-size;
}

.content[data-open='true'] {
  margin-left: var(--width-filters);
}


.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--text-black);
  margin-bottom: 4px;
}

@media screen and (max-width: 1450px) {
  .content {
    padding-inline: 20px;
  }
}
