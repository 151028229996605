.main {
  --padding-block: 6px;
  --padding-inline: 10px;
  --gap: 6px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  gap: var(--gap);
  padding-inline: var(--padding-inline);
  padding-block: var(--padding-block);

  border: var(--border-gray-1);
  border-radius: 4px;
  background: var(--color-white);

  text-wrap: balance;
  white-space: nowrap;
  width: fit-content;
  transition: filter 150ms ease-in-out;
  color: var(--color-black);
}

.main[aria-pressed='true'] {
  color: var(--color-purple-500);
  border-color: var(--color-purple-500);
  background: var(--color-purple-50);
  background: var(--color-bg2);
}

.main:hover {
  filter: brightness(97%)
}

.main:has(svg) {
  justify-content: start;
}

.main > svg {
  flex: none;
  width: 12px;
  height: 12px;
}
