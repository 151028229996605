.header {
  --gap: 10px;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 12px;
}

.filterStatus {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: var(--gap);
  align-items: baseline;
}

.title {
  font-weight: 500;
  font-size: 14px;
  /* color: var(--text-black); */
  color: var(--color-gray-400);
  cursor: default;
  user-select: none;
  line-height: 2;
  margin: 0;
  word-break: initial;
}
.title > span {
  font: inherit;
}

.titleHighlight {
  /* color: var(--color-gray-400); */
  color: var(--text-black);
  /* background-color: var(--color-gray-25); */
  /* padding: 2px 6px; */
  font-weight: bold;
  border-radius: 4px;
  white-space: nowrap;
}

.titleFilters {
  position: relative;
}

.titleFilters:hover > .titleFiltersPopover {
  display: flex;
}

.titleFiltersPopover {
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  left: -10px;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background-color: white;
  padding: 5px 10px;
  z-index: 2;
}

.titlePopoverHighlight {
  margin: 5px;
  text-transform: capitalize;
}

.saveSearchContainer {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap);

  color: var(--color-gray-400);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.searchButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 6px 10px;
  border: var(--border-gray-1);
  border-radius: 4px;
  background: var(--color-white);
  text-wrap: balance;
  transition: background-color 150ms ease-in-out;
}
.searchButton:hover {
  background: var(--color-gray-20);
}
.searchButton:has(svg) {
  padding-left: 6px;
  justify-content: start;
}
.searchButton[data-type='secondary'] {
  color: var(--color-secondary);
  border-color: var(--color-purple-300);
  /* background: var(--color-purple-50); */
  font-weight: 500;
}
.searchButton[data-type='secondary']:hover {
  background: var(--color-purple-100);
}

.searchButton[data-feed-action='remove'] {
  color: #cc0909;
}
.searchButton[data-feed-action='set'] {
  color: black;
}
.searchButton[data-status='saved'] {
  pointer-events: none;
  background: #f6fef9;
  border: 1px solid var(--color-success-400);
  color: var(--color-success-400);
}

.homeIcon {
  width: 18px;
  height: 12px;
  position: relative;
  border: none;
  margin-right: 1px;
}

@container searchContent (min-width: 40em) {
  .header {
    flex-direction: row;
  }
  .saveSearchContainer {
    justify-content: end;
  }
}
