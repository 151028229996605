.search {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;

  width: 100cqw;
  margin-block: 10px;
  background: linear-gradient(to bottom, var(--color-bg) 85%, transparent);
}

.title {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  color: var(--text-black);
  margin: 0 0 10px;
}

.searchArea {
  border: 1px solid var(--color-gray-25);
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: var(--shadow-search);
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  background-color: var(--color-white);
}

.searchArea:focus-within {
  border-color: #8f59ff;
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px
}

.input {
  flex-grow: 1;
  background: var(--color-white);
  font-size: 14px;
  line-height: 1;
  color: var(--text-black);
  border: none;
  outline: none;
  width: 100%;
}

.clear {
  cursor: pointer;
  background: none;
  border: none;
  line-height: 0;
  opacity: 0.4;
  padding: 6px;
  margin: -6px;
}

.clear:hover {
  color: var(--color-purple-600);
  opacity: 1;
}

.recent {
  display: none;
  background-color: white;
  border: 1px solid var(--color-gray-100);
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 2;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
  max-height: 50vh;
  overflow: auto;
}

.recent:empty {
  display: none;
}

.recentTitle {
  padding: 0 20px;
  cursor: default;
  text-transform: capitalize;
  color: var(--color-gray-100);
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 14px;
  line-height: 16px;
}

.recentItem {
  padding: 7px 20px;
  cursor: default;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-app-black-swamp);
  cursor: pointer;
  user-select: none;
}

.recentItem:hover {
  background-color: var(--color-app-gray-ede);
}

.recentItem:focus {
  background-color: var(--color-app-gray-ede);
  outline: none;
}

.search:focus-within .recent:not(:empty) {
  display: block;
}

.suggestions {
  scroll-behavior: smooth;
  display: none;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-25);
  border-radius: 0 0 4px 4px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 2;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
  max-height: 50vh;
  overflow: auto;
  margin-top: 2px;
}

.suggestions:empty {
  display: none;
}

.suggestionsTitle {
  padding: 10px 16px;
  cursor: default;
  text-transform: capitalize;
  color: var(--color-gray-100);
  margin: 0;
  font-size: 14px;
  line-height: 16px;
}

.suggestionsItem {
  padding: 2px 16px;
  cursor: default;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-app-black-swamp);
  cursor: pointer;
  user-select: none;
}

.suggestionsGuideItem {
  padding: 12px 16px;
  cursor: default;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-gray-100);
  cursor: not-allowed;
  pointer-events: none;
  user-select: none;
}

.suggestionsPlaceholderItem {
  padding: 7px 16px;
  cursor: default;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-gray-100);
  cursor: not-allowed;
  pointer-events: none;
  user-select: none;
}

.suggestionsItem:hover,
.suggestionsItem:focus {
  background-color: var(--color-app-gray-ede);
}

.suggestionsItem:focus {
  outline: none;
}

.suggestionsItem[data-entity-type='Actor']:hover,
.suggestionsItem[data-entity-type='Actor']:focus {
  background-color: rgba(var(--color-actors-rgb), 0.04);
}

.suggestionsItem[data-entity-type='Plot']:hover,
.suggestionsItem[data-entity-type='Plot']:focus {
  background-color: rgba(var(--color-plots-rgb), 0.04);
}

.suggestionsItem[data-entity-type='Context']:hover,
.suggestionsItem[data-entity-type='Context']:focus {
  background-color: rgba(var(--color-contexts-rgb), 0.04);
}

.suggestionsItem[data-entity-type='Brief']:hover,
.suggestionsItem[data-entity-type='Brief']:focus {
  background-color: rgba(var(--color-briefs-rgb), 0.04);
}

.suggestionsItem[data-entity-type='Country']:hover,
.suggestionsItem[data-entity-type='Country']:focus {
  background-color: rgba(var(--color-topics-rgb), 0.04);
}

.suggestionFlag {
  width: 25px;
  height: 16px;
  background-size: cover;
  margin-left: 15px;
  border: 1px solid black;
}

.search:focus-within .suggestions:not(:empty) {
  display: block;
}

.loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.loadingSpinner {
  width: 40px;
  height: 40px;
}

.tag:first-of-type {
  margin-left: 0;
}

.tag:last-of-type {
  margin-right: 10px;
}

.tag {
  display: flex;
  align-items: center;
  padding: 2px 5px;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 8px 6px;
}

.tag[data-entity-type='Actor'] {
  background: rgba(var(--color-actors-rgb), 0.21);
  border: 0.5px solid var(--color-purple-600);
}

.tag[data-entity-type='Plot'] {
  background: rgba(var(--color-plots-rgb), 0.21);
  border: 0.5px solid var(--color-plots-hex);
}

.tag[data-entity-type='Context'] {
  background: rgba(var(--color-contexts-rgb), 0.21);
  border: 0.5px solid var(--color-contexts-hex);
}

.tag[data-entity-type='Brief'] {
  background: rgba(var(--color-briefs-rgb), 0.21);
  border: 0.5px solid var(--color-gray-25);
}

.tag[data-entity-type='Country'] {
  background: rgba(var(--color-topics-rgb), 0.21);
  border: 0.5px solid var(--color-topics-hex);
}

.tagName {
  margin: 0;
  font-weight: bold;
  font-size: 11px;
  line-height: 24px;
}

.tagRemove {
  margin-left: 7px;
  width: 20px;
  height: 20px;
  border: none;
  background-color: transparent;
  position: relative;
}

.tagIcon {
  width: 11px;
  height: 11px;
}

.tagIcon:hover {
  opacity: 0.3;
}


@container searchContent (min-width: 40em) {
  .search {
    width: 60cqw;
    margin-inline: auto;
  }
}
