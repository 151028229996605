@font-face {
  font-family: 'HelveticaNowText';
  src: url('./fonts/HelveticaNowText.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText';
  src: url('./fonts/HelveticaNowText-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText';
  src: url('./fonts/HelveticaNowText-It.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNowText';
  src: url('./fonts/HelveticaNowText-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

/* Display */

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./fonts/HelveticaNowDisplay-XBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./fonts/HelveticaNowDisplay-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./fonts/HelveticaNowDisplay-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
