.context {
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: var(--color-black);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.dossier {
  flex: 1;
  margin: 0;
}

.dossier[data-mode='small-dossier'],
.dossier[data-mode='expanded-dossier'] {
  margin-bottom: 0;
  max-width: none;
}

.row[data-mode='small-dossier'] {
  flex-direction: column;
}

.left[data-mode='small-dossier'] {
  padding: 0;
  padding-bottom: 12px;
}

.left[data-mode='expanded-dossier'] {
  padding-left: 0px;
  padding-top: 0px;
}

.title[data-mode='small-dossier'] {
  font-size: 16px;
  line-height: 24px;
}

.right[data-mode='small-dossier'] {
  padding: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 1px solid var(--color-gray-75);
  border-left: none;
}

.right[data-mode='expanded-dossier'] {
  padding-right: 0;
  padding-top: 0;
}

.bottom[data-mode='small-dossier'],
.bottom[data-mode='expanded-dossier'] {
  padding-left: 0;
  padding-bottom: 0;
  padding-top: 12px;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 10px !important;
}


.button {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 4px 14px;
  cursor: pointer;
  color: var(--color-gray-600);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.button[data-compliant='true'] {
  color: var(--color-green-700);
  background-color: var(--color-green-25);
}

.button[data-compliant='true'] path {
  fill: var(--color-green-700);
}

.button[data-compliant='true']:hover {
  background-color: var(--color-green-25);
  filter: brightness(0.98);
}

.button:hover {
  background-color: var(--color-gray-10);
}

.original {
 margin-top: 16px;
}
