.main {
  --padding-top: 16px;
  --height-chart: 400px;
  height: 100dvh;
  overflow-y: auto;
  background-color: var(--color-bg);
  padding-left: 24px;
  overflow-x: hidden;
  display: grid;
  column-gap: 24px;
  grid:
    'filters filters filters' auto
    'chart chart news' var(--height-chart)
    'quotes appointments news' 1fr
    / 1fr 1fr 1fr;
}

.main > * {
  padding-top: var(--padding-top);
}

.quotes {
  grid-area: quotes;
}

.appointments {
  grid-area: appointments;
}

.news {
  grid-area: news;
  border-left: var(--border-gray-1);
  background: var(--color-white);
}

@container contentRoot (max-width: 640px) {
  .main {
    padding-inline: var(--padding-top);
    padding-bottom: var(--padding-top);
    gap: var(--padding-top);
    display: flex;
    flex-direction: column;
    scroll-snap-type: y proximity;
  }

  .main > * {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }

  .news {
    background: none;
    border-left: none;
  }
  /* title */
  .news > * > div:first-child {
    padding-inline: 0;
  }
  /* list */
  .news > * > div:last-child {
    margin-inline: -16px;
  }
}
