.main {
  display: inline-flex;
  width: fit-content;
  gap: 4px;
  background-color: var(--color-cyan-50);
  color: var(--color-cyan-800);

  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 4px 6px 4px 4px;

  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  vertical-align: text-bottom;
}

.main svg {
  width: 12px;
  height: 12px;
}
