.main {
  background-color: var(--color-gray-10);
}

.empty {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-gray-600);
}

.bookmark {
  margin: 40px auto;
  width: 50px;
  max-width: 50%;
}

.total {
  padding-top: 16px;
  padding-left: 32px;
  margin-bottom: 60px;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-black);
  margin: 0;
}

.loading {
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: var(--width-container);
}

.loadingSpinner {
  width: 50px;
  height: 50px;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: var(--width-container);
}
