.container {
  height: 26px;
  display: flex;
  font-size: 16px;
  align-items: center;
  padding: 16px;
  box-sizing: content-box;
  justify-content: space-between;
  border-bottom: var(--border-gray-1);
}

.container > div {
  gap: 1rem;
  display: flex;
  align-items: center;
}

.filterIcon {
  width: 2rem;
  height: 2rem;
  margin-left: 0.5rem;
  fill: var(--color-gray-900);
}

.closeIcon {
  width: 1.5rem;
  height: 1.5rem;
  box-sizing: content-box;
  margin-left: 0.5rem;
  fill: var(--color-gray-900);
  cursor: pointer;
  padding: 8px;
  flex: none
}
